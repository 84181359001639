@keyframes bounce {}

.bouncing-cells {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: -1; */
    pointer-events: none;
    opacity: 0.5;
    /* background-image: linear-gradient(to bottom, #3498db, #ffffff); */
    /* Set background gradient */
    animation: bounce 2s infinite;
    /* Apply animation */
}

.cell {
    position: absolute;
    background-color: #777;
    border-radius: 50%;
    pointer-events: none;
    animation: bounce 2s infinite;
}

.cell-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    /* Prevent interaction with lines */
}

/* .cell-lines line {
    stroke: #777;
    stroke-width: 1px;
} */