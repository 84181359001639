/* Example CSS in a separate .css file or styled-components */

.about-page {
    width: 90vw;
    /* display: flex; */
    /* justify-content: space-between; */
}

.bullet-points {
    width: 70%;
    float: left;
}

ul {
    list-style: none;
    padding-left: 0;
    justify-content: left;
    align-items: left;
}

li {
    font-size: 1.5em;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    /* Adjust the left margin for spacing */
}

.bullet-icon {
    padding-right: 10px;
    align-self: center;
}

.bullet-points ul {
    list-style-type: none;
    padding-left: 0;
}

.image-section {
    float: left;
    width: 350px;
    height: 350px;
    overflow: hidden;
}

.round-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}