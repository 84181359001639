/* App.css */

/* CSS reset */

/* * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    outline: none;
} */

html {
    overflow-y: hidden !important;
}

.app {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #222;
    /* Darker background */
    color: white;
    /* White text color */
}

.button {
    background-color: #222 !important;
}

.button.is-focused,
.button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.title {
    /* font-family: 'Roboto', sans-serif !important; */
    font-size: 14rem !important;
    text-shadow: 5px 5px #0000005e;
}


.header {
    background-color: #111 !important;
    /* Slightly lighter grey */
    height: 3rem;
    /* Set a fixed height */
    padding: 0.5rem !important;
    margin: 0 !important;
    /* Important to override Bulma's padding */
    text-align: center;
    font-size: small;
    display: flex;
    align-items: center;
    z-index: 1;
    /* Vertically center the text */
    /* justify-content: center; */
    /* Horizontally center the text */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.header-content {
    width: 100%;
    padding: 1rem;
    /* Add padding for spacing */
}

.main {
    flex: 1;
    display: flex;
    align-items: center;
    /* Vertically center the content */
    justify-content: center;
    /* Horizontally center the content */
}

.middle-section {
    text-align: center;
}

.middle-section h2,
.middle-section p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}

.footer {
    z-index: 1;
    background-color: #111 !important;
    /* Slightly lighter grey */
    height: 3rem;
    /* Set a fixed height */
    padding: 0.5rem !important;
    margin: 0 !important;
    /* Important to override Bulma's padding */
    text-align: center;
    font-size: small;
    display: flex;
    align-items: center;
    /* Vertically center the text */
    justify-content: center;
    /* Horizontally center the text */
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.3);
}


.footer-icon {
    font-size: 1.5rem;
    color: #ffffff;
    margin: 0 20px;
    transition: color 0.3s ease-in-out;
}

.footer-icon:hover {
    color: #777;
}

.cell {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #0074d9;
    /* Cell background color */
    border-radius: 50%;
    pointer-events: none;
    /* Prevent interaction with cells */
}

.cell {
    animation: bounce 2s infinite;
}

.page {
    position: absolute;
}

.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

/* Page exit animation */
.page-exit {
    opacity: 0;
    position: absolute;
}

/* .page-exit-active {
    opacity: 0;
    transition: opacity 300ms;
} */